<template>
	<a-modal :title="this.$route.name" :width="800" :visible="visible" @ok="ok" @cancel="cancel">
		<div class="p1">
			<p>姓名：张三</p>
			<p>性别：男</p>
			<p>身高：177厘米</p>
			<p>体重：170斤</p>
			<p>过去病史：无</p>
			<p>家族病史：无</p>
			<p>注册时间：2023年7月22日</p>
			<p>自动发送时间：
				<a-select style="width:80px">
					<a-select-option value="每天">每天</a-select-option>
					<a-select-option value="每周">每周</a-select-option>
					<a-select-option value="每月">每月</a-select-option>
				</a-select>
				<a-time-picker use12-hours style="margin-left:10px" />
			</p>
		</div>
		<div class="p2">
			<a-row>
				<a-col :span="6">
					<div class="d1">体检项目清单选择</div>
					<div class="d2">
						<a-tree :tree-data="type" checkable />
					</div>
				</a-col>
				<a-col :span="6">
					<div class="d1">发送给谁名单</div>
					<div class="d2">
						<a-tree :tree-data="user" />
					</div>
				</a-col>
				<a-col :span="6">
					<div class="d1">体检报告模板选择</div>
					<div class="d2">
						<a-tree :tree-data="tpl" checkable />
					</div>
				</a-col>
				<a-col :span="6">
					<div class="d1">体检报告发送日志</div>
					<div class="d3">
						<a>2023-7-22 18:30 自动发送</a>
						<a>2023-7-22 18:30 手动发送</a>
						<a>2023-7-22 18:30 自动发送</a>
					</div>
				</a-col>
			</a-row>
		</div>
	</a-modal>
</template>

<script>
import pick from "lodash.pick"

// 表单字段

export default {
	props: {
		visible: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			type: [{ title: '心率', key: 1 }, { title: '心率', key: 2 }, { title: '心率', key: 3 }, { title: '心率', key: 4 }, { title: '心率', key: 5 }],
			user: [{ title: '张医生', key: 1, children: [{ title: '电话：133133333333' }, { title: '邮箱：wrna@163.com' }, { title: '微信：朱朱，王冉' }] }, { title: '李医生', key: 2, children: [{ title: '电话：133133333333' }, { title: '邮箱：wrna@163.com' }, { title: '微信：朱朱，王冉' }] }],
			tpl: [{ title: '简易版', key: 1 }, { title: '升级版', key: 2 }]
		}
	},
	created() {
	},
	methods: {
		init(e) {
			this.visible = true
		},
		cancel() {
			this.visible = false
		},
		ok() {
			this.visible = false
		}
	}
}
</script>

<style>
.p2 {
	padding-top: 20px;
}

.p2 .d3 {
	padding-top: 5px;
}

.p2 .d3 a {
	padding-bottom: 5px;
	display: flex
}
</style>
