<template>
	<div class="qiqb-table">
		<div class="qiqb-table-search">
			<a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
				<a-row>
					<a-col :span="6">
						<a-form-item label="注册时间">
							<a-range-picker @change="change_time" />
						</a-form-item>
					</a-col>
					<a-col :span="6">
						<a-form-item label="用户姓名">
							<a-input v-model="queryParam.name" placeholder="请输入" />
						</a-form-item>
					</a-col>
					<a-col :span="6">
						<a-form-item label="手机号码">
							<a-input v-model="queryParam.phone" placeholder="请输入" />
						</a-form-item>
					</a-col>
					<a-col :span="6">
						<div class="qiqb-table-search-btn">
							<a-button type="primary" @click="getList({current:1})">查询</a-button>
							<a-button style="margin-left: 8px" @click="chongzhi">重置</a-button>
							<!-- <a-button style="margin-left: 8px" @click="more({ current: 1 })">导出</a-button> -->
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6">
						<a-form-item label="用户性别">
							<a-select v-model="queryParam.sex" placeholder="请选择"
								:options="[{ label: '男', value: 1 }, { label: '女', value: 2 }]" />
						</a-form-item>
					</a-col>
				</a-row>
			</a-form>

		</div>

		<div class="qiqb-table-item">
			<div class="table-operator">
				<div class="table-operator-title">{{ this.$route.name }}</div>
			</div>
			<div class="table-item">
				<a-table :columns="columns" :data-source="list" :pagination="page" size="middle" @change="getList">
					<span slot="sexs" slot-scope="text">
						{{ text.sex == 1 ? '男' : '女' }}
					</span>
					<span slot="action" slot-scope="text, record">
						<a @click="look(record)">查看</a>
					</span>
				</a-table>
			</div>
		</div>
		<add ref="add" @ok="ok" />
		<a-modal v-model="show" title="用户档案" centered @ok="modal2Visible = false" width="1000px">
			<div class="border1 flex-b">
				<div>
					<p>姓名：{{ info1.name }}</p>
					<p>性别：{{ info1.sex == 0 ? '男' : '女' }}</p>
					<p>身高：{{ info1.height }}</p>
					<p>体重：{{ info1.weight }}</p>
					<p>过去病史：{{ info1.pastHistoryNames }}</p>
					<p>家族病史：{{ info1.familyHistoryNames }}</p>
					<p>注册时间：{{ info1.createTime }}</p>
				</div>
				<div>
					<img :src="info1.headImg" style="width: 100px;height: 100px;" alt="">
				</div>
			</div>
			<div class="flex-b" style="align-items: flex-start; margin-top: 30px;">
				<div class="border">
					<p style="font-weight: 600;">体检项目清单选择</p>
					<div v-for="(item, index) in info2" :key="index">
						<p v-if="item.type == 'choose'">{{ item.name }}</p>
					</div>
				</div>
				<div class="border">
					<p style="font-weight: 600;">发送给谁名单</p>
					<div v-for="(item, index) in info3" :key="index">
						<p>{{ item.name }}</p>
						<div style="margin-left: 10px;">
							<p>电话：{{ item.phone }}</p>
							<p>邮箱：{{ item.email }}</p>
							<p v-if="item.wxLists">微信：
								<span v-for="(v, i) in item.wxLists" :key="i">
									{{ v }}
								</span>
							</p>
						</div>
					</div>
				</div>
				<div>
					<div class="border" style="max-height: 300px;">
						<p style="font-weight: 600;">体检报告模板选择</p>
						<div style="text-align: center;" v-for="(item, index) in info4" :key="index">
							<img :src="item.fileUrl" style="width: 100px;" alt="">
							<p>{{ item.name }}</p>
						</div>
					</div>
					<div class="border" style="margin-top: 20px;height: 180px;">
						<p style="font-weight: 600;">自动发送时间选择</p>
						<div v-if="info5">
							<div v-if="info5.day !== 0">
								每天 {{ info5.hour }}:{{ info5.minutes }}
							</div>
							<div v-if="info5.week !== 0">
								每周<text v-if="info5.week == 1">一</text> <text v-if="info5.week == 2">二</text>
								<text v-if="info5.week == 3">三</text>
								<text v-if="info5.week == 4">四</text>
								<text v-if="info5.week == 5">五</text>
								<text v-if="info5.week == 6">六</text>
								<text v-if="info5.week == 7">日</text>
								{{ info5.hour }}:{{ info5.minutes }}
							</div>
							<div v-if="info5.intervalDays !== 0">
								每隔 {{ info5.intervalDays }} 天 {{ info5.hour }}:{{ info5.minutes }}
							</div>
						</div>
						<div v-else>
							暂未选择
						</div>
					</div>
				</div>


				<div class="border">
					<p style="font-weight: 600;">体检报告发送日志</p>
					<p v-for="(item, index) in info6" :key="index">{{ item.sendTime }} <span style="margin-left: 20px;">{{
						item.type == 1 ? '手动发送' : '自动发送' }}</span> </p>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import add from "./modules/add"
import { personlist, userdetail } from "../../../api/index.js"

export default {
	components: {
		add
	},
	methods: {
		//将时间戳转换成日期格式
		timestampToTime(timestamp) {
			var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
			var Y = date.getFullYear() + '-';
			var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
			var h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
			var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
			var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
			return Y + M + D + h + m + s;
		},

		async getList(e) {
			// console.log(e.current);
			this.queryParam.current = e.current
			let res = await personlist(this.queryParam)
			if (res.status == 200) {
				this.page.total = res.data.total;
				this.page.current = res.data.current;
			}
			this.list = res.data.records
			// this.total = res.data.total
			this.list.forEach(v => {
				v.birthday = this.timestampToTime(v.birthday)
			})
			console.log(this.list);

		},
		nextPage(e) {
			// this.queryParam.current++
			this.queryParam.page = e.current
			this.getList()
		},
		change_time(e, str) {
			console.log(e, str);
			this.queryParam.startTime = str[0]
			this.queryParam.endTime = str[1]
		},
		edit(record) {
			this.id = record.id
			// this.$refs.add.init(record)
			this.$router.push({ name: 'info', query: { id: record.id } })
		},
		ok(e) {

		},
		async look(e) {
			let res = await userdetail({ id: e.id })
			this.info1 = res.data.basicInfo
			this.info2 = res.data.userChooseHealth
			this.info3 = res.data.sendToList
			this.info4 = res.data.templateReports
			this.info5 = res.data.sendTime
			this.info6 = res.data.sendLog
			this.show = true
		},
		del(e, status) {

		},
		chongzhi() {
			this.queryParam = {
				size: 20,
				current: 1,
				phone: '',
				name: '',
				startTime: '',
				endTime: '',
				sex: ''
			}
			this.getList({current:1})
		},
	},
	mounted() {
		this.getList({ current: 1 });
	},

	data() {
		return {
			id: 0,
			queryParam: {
				size: 20,
				current: 1,
				phone: '',
				name: '',
				sendType: 0,
				templateCode: '',
				startTime: '',
				endTime: '',
				sex: ''
			},
			show: false,
			info1: {},
			info2: {},

			info3: {},
			info4: {},
			info5: {},
			info6: {},

			page: { pageSize: "20", total: 0, current: 0 },
			columns: [
				// { title: '报告编号', dataIndex: 'id',width: 80  },
				{ title: "用户姓名", dataIndex: "name", width: 100, ellipsis: true },
				{ title: "手机号码", dataIndex: "phone", width: 120 },
				{ title: "性别", width: 60, key: "sexs", scopedSlots: { customRender: "sexs" } },
				{ title: "出生日期", dataIndex: "birthday", width: 100 },
				{ title: "身高", dataIndex: "height", width: 60 },
				{ title: "体重", dataIndex: "weight", width: 60 },
				{ title: "过往史", dataIndex: "pastHistoryNames", width: 80, ellipsis: true },
				{ title: "家族史", dataIndex: "familyHistoryNames", width: 80, ellipsis: true },
				{ title: "选择模板", dataIndex: "modelNames", width: 100, ellipsis: true },
				{ title: "自动发送", dataIndex: "time", width: 100 },
				{ title: "发送时间", dataIndex: "createTime" },
				{ title: "操作", width: 60, scopedSlots: { customRender: "action" } },
			],
			item: [],
			type: [],
			list: [],
			total: '',
			time: ''
		};
	}
};
</script>
<style>
.flex-s {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flex-b {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.border {
	border: 1px solid #999;
	padding: 10px 20px;
	height: 500px;
	overflow: auto;
}

.border1 {
	border: 1px solid #999;
	padding: 10px 20px;
}
</style>